
import {auth} from '../firebase'
import {useRouter} from 'vue-router';

import { signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";





export const useUser= () => {

    const router = useRouter();
    
   

        const signIn = async() => {
            const provider = new GoogleAuthProvider();
            console.log("Provider creado, vamos a por el popup")
            console.log(auth)
            
            signInWithPopup(auth, provider)
            .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            console.log(token)
            const user = result.user;
            //TO DO: Ahora tenemos que ver si el usuario esta registrado


            console.log(user)
            router.push('/perfil')
            
            // IdP data available using getAdditionalUserInfo(result)
            // ...
                }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
           // const email = error.customData.email;
            // The AuthCredential type that was used.
           // const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            console.log(errorCode +" - "+errorMessage)
            });         
        }


        const googleSignOut =async()=>{
            
            signOut(auth).then(() => {
            router.push('/');
            }).catch((error) => {
            // An error happened.
            console.log(error)
            });
        }

        return {signIn,googleSignOut};

        
        
}

export {auth}