import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { getCurrentUser } from '@/composables/users'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      requiresAuth:false
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },meta:{
      requiresAuth:false
    }
  },
  {
    path: '/perfil',
    name: 'perfil',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/PerfilView.vue')
    },
    meta:{
      requiresAuth:true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async(to,from,next)=>{
  const requiresAuth =to.matched.some(record=>record.meta.requiresAuth);
  if (requiresAuth && !(await getCurrentUser())){
    console.log("Hola SI estas protegido")
    next('/');
  }else{
    console.log("Hola no estas protegido o lo estas y tienes user")
    next();
  }
});

export default router
