<template>
    <nav class="navbar navbar-dark bg-dark">
        <div class="container">
            <router-link class="navbar-brand" to="/">{{userName}}</router-link>
            <div>
                <button class="btn btn-primary mx-2" @click="signIn"
                v-if="!isAuthenticated"
                >Acceder</button>
                <button class="btn btn-danger mx-0" @click="googleSignOut" v-if="isAuthenticated">Salir</button>
            </div>
        </div>
    </nav>
  </template>
  
  <script>
  
  import{useUser,auth} from '../composables/useUsers';
  import { useAuth } from '@vueuse/firebase/useAuth'
  import {computed} from 'vue'
  
  
  export default {
      setup(){
          
         const {signIn,googleSignOut} =useUser();
         
         const { isAuthenticated, user } = useAuth(auth)
  
         const userName=computed(()=>{
              return isAuthenticated.value ? user.value.displayName: 'Sin Auth'
         })
         
         return {signIn,googleSignOut,isAuthenticated,user,userName};
      }
  
  }
  </script>
  
  <style>
  
  </style>