

import { onAuthStateChanged} from 'firebase/auth';
import { auth } from '@/firebase';

const getCurrentUser=()=>{
    console.log("Entrando en getCurrentUser")
    
    return new Promise((resolve,reject)=>{
      const unsuscribe= onAuthStateChanged(auth,(user)=>{
        unsuscribe()
        resolve(user)
        /*if (user){
          console.log("Obtengo el user")
          unsuscribe()
          resolve(user);
  
        }else{
          console.log("No tengo el user")
          resolve(user)
        }*/
      },reject)
    })
  
  }

  export {getCurrentUser}